<template>
	<b-card border-variant="primary" body-class="p-0" class="eggs-card">
		<b-card-body>
			<h2 class="eggs-card__title mt-50 mb-1">Huevos disponibles:</h2>
			<button class="cursor-pointer mb-3 eggs-card__button" @click="openEggsAvailable">
				<span>{{ eggsAvailable }}</span>
				<svg width="31" height="23" viewBox="0 0 31 23" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M29.9887 20.2382L29.9882 20.2376C29.8731 20.0871 29.7436 19.9417 29.62 19.8031C29.5784 19.7563 29.5374 19.7104 29.4979 19.6652C28.9313 19.013 28.3606 18.3603 27.7862 17.7035C27.3842 17.2438 26.9805 16.7821 26.5752 16.3172C29.935 12.3372 29.4729 7.01004 26.3243 3.71042L26.3243 3.71041C23.1379 0.371804 17.9023 -0.108778 14.1764 2.60943C10.3589 5.38482 9.21037 10.492 11.4479 14.5922L11.4484 14.5931C12.5717 16.6173 14.2245 18.0598 16.3918 18.8448L16.3922 18.8449C19.3484 19.9073 22.1714 19.5099 24.8039 17.8513C24.9834 18.0593 25.1579 18.2567 25.3272 18.4483C25.4541 18.5919 25.5781 18.7323 25.6993 18.8714L25.6997 18.8718C26.0392 19.2586 26.3759 19.6464 26.7122 20.0338C27.1952 20.5903 27.6775 21.1459 28.1665 21.6961L28.1673 21.6969C28.4006 21.9554 28.6803 22.1094 28.9732 22.143C29.2666 22.1767 29.5588 22.088 29.8114 21.8876C30.3207 21.4979 30.3902 20.7692 29.9887 20.2382ZM19.5896 17.1016C15.7208 17.0904 12.652 13.9668 12.6746 10.0727C12.6971 6.2488 15.7873 3.19218 19.6115 3.20335C23.4943 3.22605 26.5956 6.32904 26.5844 10.1866C26.5731 13.9982 23.4145 17.1129 19.5896 17.1016Z"
						fill="white"
						stroke="white"
						stroke-width="0.3"
					/>
				</svg>
			</button>
			<h3 class="eggs-card__subtitle">
				<span class="sb-label">Madrillas:</span>
				<span class="sb-count">{{ counterMadrillas }}</span>
			</h3>
		</b-card-body>

		<div>
			<b-modal v-model="controllerEggsAvailable" title="Huevos disponibles" body-class="m-0 p-0">
				<div class="">
					<b-table-simple sticky-header class="position-relative table-eggs-available">
						<b-thead>
							<b-tr class="text-center">
								<b-th class=""> # </b-th>
								<b-th class=""> Madrillas </b-th>
								<b-th class=""> Huevos </b-th>
							</b-tr>
						</b-thead>

						<b-tbody>
							<template v-for="(item, index) in arrayMadrillas">
								<b-tr class="text-center" :key="index">
									<b-td>{{ index + 1 }}</b-td>
									<b-td>
										<SpecimenPlate
											:specimen="{
												plate: item.madrilla.plate,
												alias: item.madrilla.alias,
												id: item.id,
												thumb: item.madrilla.thumb,
												image: item.madrilla.image,
											}"
										/>
									</b-td>

									<b-td>
										<p class="eggs-number">{{ item.eggs_quantity }}</p>
									</b-td>
								</b-tr>
							</template>
						</b-tbody>
						<!-- <b-tfoot sticky-footer>
							
						</b-tfoot> -->
					</b-table-simple>
				</div>
				<template #modal-footer>
					<div class="w-100 px-3 d-flex justify-content-between align-items-center">
						<h4 class="mb-0">Total:</h4>
						<h4 class="mb-0">{{ eggsAvailable }}</h4>
					</div>
					<!-- <b-row class="w-100">
						<b-col cols="12" md="4"></b-col>
						<b-col cols="12" md="4" sm="6" class="font-weight-bolder text-center">Total</b-col>
						<b-col cols="12" md="4" sm="6" class="font-weight-bolder text-center">{{
							eggsAvailable
						}}</b-col>
					</b-row> -->
					<!-- <h4 class="eggs-number font-weight-bolder mr-1">TOTAL</h4>
					<h4 class="eggs-number font-weight-bolder">{{ eggsAvailable }}</h4> -->
				</template>
			</b-modal>
		</div>
	</b-card>
</template>

<script>
import { bus } from "@/main";
// import lotesService from "../../encaste/lotes/service/lotes.service";
import eggService from "@/services/egg.service";
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue";

export default {
	name: "EggsAvailable",
	components: { SpecimenPlate },
	props: {
		reload: {
			type: Number,
			default: null,
		},
		start_date: {
			type: String,
		},
		end_date: {
			type: String,
		},
	},
	data() {
		return {
			eggsAvailable: 0,
			counterMadrillas: 0,
			controllerEggsAvailable: false,
			arrayMadrillas: [],
		};
	},
	created() {
		bus.$on("reload-eggs-available", async () => {
			await this.init();
		});
	},
	destroyed() {
		bus.$off("reload-eggs-available");
	},
	async mounted() {
		await this.init();
	},
	methods: {
		async init() {
			this.isPreloading();
			const { data } = await eggService.eggsAvailableWithMadrillas({
				start_date: this.start_date,
				end_date: this.end_date,
			});
			this.eggsAvailable = data.eggs;
			this.counterMadrillas = data.madrillas;
			// this.isPreloading(false);
		},
		async openEggsAvailable() {
			this.isPreloading();
			const { data } = await eggService.posturasWithEggsByMadrillas({
				start_date: this.start_date,
				end_date: this.end_date,
			});
			this.arrayMadrillas = data;

			let total = 0;
			this.arrayMadrillas.reduce((p, a) => {
				return p?.eggs_quantity * 1 + a?.eggs_quantity * 1;
			}, 0);
			this.arrayMadrillas.forEach((p) => {
				total += p.eggs_quantity * 1;
			});

			this.controllerEggsAvailable = true;
			this.isPreloading(false);
		},
		closeEggsAvailable() {
			this.controllerEggsAvailable = false;
		},
		linkSpecimen(id) {
			window.open(`/ejemplares/detalle/${id}`, "_blank");
		},
	},
	watch: {
		async start_date(oldVal, newVal) {
			await this.init();
		},
	},
};
</script>

<style lang="scss" scoped>
.container-eggs-available {
	background: #fff;

	.header-eggs-available {
		background: #fff;
	}

	.footer-eggs-available {
		position: sticky;
		bottom: 0;
		background: #fff;
	}

	h3 {
		padding: 1rem;
		font-size: 1.8rem !important;
		margin-bottom: 0;
	}
}

.header {
	.title {
		font-size: 1.2rem;
	}

	margin-bottom: 0.5rem !important;
}

.item-eggs-available {
	border-bottom: 1px solid #e0e0e0;
	padding: 0.5rem 0;

	.madrilla {
		text-align: center;

		.alias {
			margin-bottom: 0;
			font-size: 12px;
		}

		.plate-madrilla {
			margin-bottom: 0;
		}
	}

	.eggs-number {
		text-align: center;
		margin-bottom: 0 !important;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.container-eggs-available-open {
	height: 0;
	min-height: 0;
	max-height: 0;
	transition: 0.5s all ease-in-out;
	overflow: hidden;
}

.table-eggs-available {
	min-height: 75vh !important;
}
.table-eggs-available::-webkit-scrollbar {
	width: 4px;
	background: #d5d1fb;
}

.table-eggs-available::-webkit-scrollbar-thumb {
	background: #7367f0;
	border-radius: 10px;
}
.eggs-card {
	background-color: rgba(115, 103, 240, 0.1);
	&__title {
		font: normal 400 1.8rem/1.25 "Rubik";
	}
	&__button {
		border: none;
		outline: none;
		background-color: var(--primary);
		color: white;
		border-radius: 9999px;
		padding: 0.5em 1em;
		font: normal 500 1.7rem/1 "Rubik";
		display: inline-flex;
		align-items: center;
	}
	&__subtitle {
		font: normal 400 1.5rem/1.25 "Rubik";
		.sb-count {
			padding: 0 0.5em;
			font-weight: 500;
		}
	}
}
</style>
