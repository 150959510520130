<template>
	<b-modal
		ref="modal-registro-huevos-create"
		@hidden="$emit('hidden')"
		title="Agregar huevos"
		size="lg"
		cancel-title="Cancelar"
		no-close-on-backdrop
		scrollable
	>
		<div style="height: 100vh">
			<validation-observer ref="form">
				<b-row class="header-date-total">
					<b-col cols="12" md="4">
						<b-form-group label="Fecha" label-for="date">
							<b-form-datepicker
								:max="new Date()"
								id="date"
								locale="es"
								v-model="registerEggsDate"
								:date-format-options="{
									year: 'numeric',
									month: 'numeric',
									day: 'numeric',
								}"
								placeholder="Fecha"
							/>
						</b-form-group>
					</b-col>
					<b-col
						cols="12"
						md="4"
						class="d-flex justify-content-md-center justify-content-between align-items-center"
					>
						<label class="mr-1">Cantidad de posturas</label>
						<h4 class="mb-0">{{ arrayMadrillasSelected.length }}</h4>
					</b-col>
					<b-col
						cols="12"
						md="4"
						class="d-flex justify-content-md-center justify-content-between align-items-center"
					>
						<label class="mr-1">Total de huevos</label>
						<h4 class="mb-0">{{ totalRegisterEggs }}</h4>
					</b-col>
				</b-row>
				<b-row v-for="(postura, index) in arrayPosturas" :key="index">
					<b-col cols="12" lg="8" class="d-flex align-items-center">
						<b-form-group
							class="flex-grow-1"
							label="Madrilla - Padrillo - Postura"
							:label-for="'postura-madrilla-padrillo-' + index"
						>
							<validation-provider rules="required" v-slot="{ errors }">
								<v-select
									:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									v-model="postura.postura_id"
									:options="arrayMadrillasByProcessingPosturas"
									:id="'postura-madrilla-padrillo-' + index"
									:clearable="false"
									label="placa_madrilla"
									:reduce="(option) => option"
									@open="scrollToBotom(index)"
									@input="selectOnChange(postura, index)"
									:filter="fuseSearch"
									:selectable="(option) => !option.isDisabled"
									autoscrollf
								>
									<template
										#selected-option="{
											padrillo_alias,
											placa_padrillo,
											madrilla_alias,
											placa_madrilla,
											code,
										}"
									>
										<span v-if="madrilla_alias">{{ madrilla_alias }} / </span>
										({{ placa_madrilla }}) -
										<span v-if="padrillo_alias" style="margin-left: 5px"
											>{{ padrillo_alias }} /
										</span>
										({{ placa_padrillo }}) - ({{ code }})
									</template>
									<template
										v-slot:option="{
											padrillo_alias,
											placa_padrillo,
											madrilla_alias,
											placa_madrilla,
											code,
										}"
									>
										<div class="d-flex align-items-center">
											<div class="text-center" style="min-width: 100px">
												<div>{{ placa_madrilla }}</div>
												<span v-if="madrilla_alias">
													<small>{{ madrilla_alias }} </small>
												</span>
											</div>
											<span class="font-weight-bold" style="font-size: 14px; margin: 0 10px">
												|
											</span>
											<div class="text-center" style="min-width: 100px">
												<div>{{ placa_padrillo }}</div>
												<span v-if="padrillo_alias">
													<small>{{ padrillo_alias }} </small>
												</span>
											</div>
											<span class="font-weight-bold" style="font-size: 14px; margin: 0 10px">
												|
											</span>
											<div>
												{{ code }}
											</div>
										</div>
									</template>
								</v-select>
								<span class="text-danger">{{ errors[0] }}</span>
							</validation-provider>
						</b-form-group>
					</b-col>
					<b-col cols="12" md="8" lg="3">
						<b-form-group label="Cantidad" label-for="single-quantity">
							<b-form-spinbutton id="single-quantity" min="1" max="100" v-model="postura.number_eggs" />
						</b-form-group>
					</b-col>
					<b-col cols="12" md="4" lg="1" class="d-flex align-items-center justify-content-sm-end">
						<feather-icon
							:disabled="index == 0"
							class="cursor-pointer text-danger"
							@click="deletePostura(index, postura)"
							icon="TrashIcon"
							size="20"
						/>
					</b-col>
					<b-col cols="12">
						<hr />
					</b-col>
				</b-row>
			</validation-observer>
		</div>

		<template #modal-footer="{ cancel }">
			<div class="d-flex justify-content-between w-100">
				<b-button @click="cancel" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="secondary" v-if="!show">
					<span>Cancelar</span>
				</b-button>
				<b-button variant="secondary" disabled v-else>
					<b-spinner small />
					Cargando...
				</b-button>

				<div>
					<b-button
						class="mr-1"
						v-ripple.400="'rgba(255, 255, 255, 0.15)'"
						variant="info"
						v-if="!show"
						@click="addPosturas()"
					>
						<feather-icon icon="PlusIcon" class="mr-25" />
						<span>Agregar nuevo</span>
					</b-button>
					<b-button class="mr-1" variant="info" disabled v-else>
						<b-spinner small />
						Cargando...
					</b-button>

					<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" @click="save()" variant="primary" v-if="!show">
						<span>Guardar</span>
					</b-button>
					<b-button variant="primary" disabled v-else>
						<b-spinner small />
						Cargando...
					</b-button>
				</div>
			</div>
		</template>
	</b-modal>
</template>

<script>
import { bus } from "@/main";
import modalMixin from "@/mixins/modal.js";
import eggService from "@/services/egg.service";
import moment from "moment";
import Ripple from "vue-ripple-directive";
export default {
	directives: {
		Ripple,
	},
	mixins: [modalMixin],
	props: {
		selectedEggId: {
			type: Number,
			default: 0,
		},
	},
	computed: {
		totalRegisterEggs() {
			let sum = 0;
			for (let i = 0; i < this.arrayPosturas.length; i++) {
				if (this.arrayPosturas[i].postura_id) {
					sum += parseFloat(this.arrayPosturas[i].number_eggs);
				}
			}
			return sum;
		},
	},
	mounted() {
		this.isPreloading();
		this.registerEggsDate = moment().format("YYYY-MM-DD");
		this.getMadrillasByProcessingPosturas();

		this.toggleModal("modal-registro-huevos-create");
		this.isPreloading(false);
	},
	data() {
		return {
			arrayMadrillasByProcessingPosturas: [],
			arrayPosturas: [
				{
					placa_madrilla: null,
					number_eggs: 1,
					error: null,
					postura_id: null,
					id: null,
				},
			],
			validateMadrillaInfoObject: {
				text: "",
				class: "",
				isMadrilla: false,
			},
			registerEggsDate: null,
			registerEggsDescription: null,
			show: false,
			arrayMadrillasSelected: [],
			disabledCounter: 0,
			modalPendingPostures: false,
		};
	},
	methods: {
		fuseSearch(options, search) {
			const fuse = new Fuse(options, {
				keys: ["placa_madrilla", "madrilla_alias", "placa_padrillo", "padrillo_alias", "postura_code"],
				shouldSort: true,
			});
			return search.length ? fuse.search(search).map(({ item }) => item) : fuse.list;
		},
		getMadrillasByProcessingPosturas() {
			axios.get("/api/postura/get-madrillas-for-register-eggs").then((response) => {
				this.arrayMadrillasByProcessingPosturas = response.data;
				for (let i = 0; i < this.arrayMadrillasByProcessingPosturas.length; i++) {
					this.arrayMadrillasByProcessingPosturas[i].status = 1;
				}
				if (this.selectedEggId != 0) {
					this.getEgg();
				}
			});
		},
		getEgg() {
			axios
				.post("/api/egg/get-egg", {
					egg_id: this.selectedEggId,
				})
				.then((res) => {
					this.registerEggsDate = res.data[1][0].egg_postura_date;
					this.registerEggsDescription = res.data[1][0].description;
					let posturas = res.data[0];
					for (let i = 0; i < posturas.length; i++) {
						for (let j = 0; j < this.arrayMadrillasByProcessingPosturas.length; j++) {
							if (posturas[i].postura_id == this.arrayMadrillasByProcessingPosturas[j].id) {
								posturas[i].postura_id = this.arrayMadrillasByProcessingPosturas[j];
							}
						}
					}
					this.arrayPosturas = posturas;
				});
		},
		async save() {
			if (!(await this.$refs.form.validate())) {
				this.$swal({
					icon: "error",
					title: "Campos inválidos",
					text: "Para confirmar esta revisión debes llenar todos los campos",
				});
				return;
			}
			let contador = 0;
			let previousPlacaMadrilla = "";
			for (let i = 0; i < this.arrayPosturas.length; i++) {
				if (
					this.arrayPosturas[i].placa_madrilla == previousPlacaMadrilla &&
					this.arrayPosturas[i].placa_madrilla != null
				) {
					this.arrayPosturas[i].error = "Esta madrilla ya está en uso";
					contador = contador + 1;
				}
				previousPlacaMadrilla = this.arrayPosturas[i].placa_madrilla;
			}
			if (contador == 0) {
				this.show = true;
				await eggService.registerEggs({
					register_eggs_date:    this.registerEggsDate,
					posturas: 			   this.arrayPosturas,
					created_by: 		   this.currentUser.user_id,
					register_eggs_comment: this.registerEggsDescription,
					total_register_eggs:   this.totalRegisterEggs,
				});
				this.show = false;
				this.showSuccessToast("Se han registrado los huevos correctamente!");
				bus.$emit("reload-eggs-available");
				this.$emit("hidden");
			}
		},
		editEggsToPostura() {
			let contador = 0;
			let previousPlacaMadrilla = "";
			for (let i = 0; i < this.arrayPosturas.length; i++) {
				if (
					this.arrayPosturas[i].placa_madrilla == previousPlacaMadrilla &&
					this.arrayPosturas[i].placa_madrilla != null
				) {
					this.arrayPosturas[i].error = "Esta madrilla ya está en uso";
					contador = contador + 1;
				}
				previousPlacaMadrilla = this.arrayPosturas[i].placa_madrilla;
			}
			if (contador == 0) {
				axios
					.post("/api/postura/edit-eggs", {
						register_eggs_date: this.registerEggsDate,
						posturas: this.arrayPosturas,
						egg_id: this.egg.id,
						register_eggs_comment: this.registerEggsDescription,
						total_register_eggs: this.totalRegisterEggs,
					})
					.then(() => {
						this.showSuccessToast("Se ha editado el registro de huevos correctamente!");
						this.$emit("closeModal");
					});
			}
		},
		selectOnChange(postura, index) {
			this.arrayMadrillasSelected.push({
				id: index,
				postura: postura,
			});
			if (this.arrayMadrillasSelected.filter((v) => v.id == index).length > 1) {
				var idx = this.arrayMadrillasSelected.findIndex((i) => i.id == index);
				if (idx >= 0) {
					this.arrayMadrillasSelected.splice(idx, 1);
				}
			}
			for (let i = 0; i < this.arrayMadrillasByProcessingPosturas.length; i++) {
				this.arrayMadrillasByProcessingPosturas[i].isDisabled = false;
				this.disabledCounter = 0;
			}
			for (let i = 0; i < this.arrayMadrillasSelected.length; i++) {
				if (this.arrayMadrillasSelected[i].postura.postura_id.id != "") {
					this.arrayMadrillasByProcessingPosturas.find(
						(x) => x.id == this.arrayMadrillasSelected[i].postura.postura_id.id
					).isDisabled = true;
					this.disabledCounter++;
				}
			}
			if (this.disabledCounter == this.arrayPosturas.length) {
				this.addPosturas();
			}

			this.arrayMadrillasByProcessingPosturas = this.arrayMadrillasByProcessingPosturas.sort(
				(a, b) => a.isDisabled - b.isDisabled
			);
		},
		addPosturas() {
			let objectPostura = {
				placa_madrilla: null,
				number_eggs: 1,
				error: null,
				postura_id: null,
				id: null,
			};
			this.arrayPosturas.push(objectPostura);
		},
		deletePostura(index, postura) {
			if (postura.postura_id) {
				this.arrayMadrillasByProcessingPosturas.find((x) => x.id == postura.postura_id.id).isDisabled = false;
				this.disabledCounter--;
			}
			if (this.arrayPosturas.length == 1) {
				return;
			}
			this.arrayPosturas.splice(index, 1);
		},
		decreaseNumberEggs(postura) {
			postura.number_eggs = Number(postura.number_eggs) - 1;
		},
		increaseNumberEggs(postura) {
			postura.number_eggs = Number(postura.number_eggs) + 1;
		},
		scrollToBotom(index) {
			//   let miRef = this.$refs.modalRegistroHuevosCreate.$refs.content.querySelector("div");
			if (index + 1 > 2) {
				let myRef = this.$refs["modal-registro-huevos-create"].$refs.content.querySelector("div");
				myRef.style.scrollBehavior = "smooth";
				setTimeout(() => {
					myRef.scrollTop = myRef.scrollHeight - myRef.clientHeight;
				}, 100);
			}
		},
	},
};
</script>

<style scoped lang="scss">
.header-date-total {
	background: #ffffff;
	padding-bottom: 5px;
	padding-top: 10px;
	position: sticky;
	top: -15px !important;
	z-index: 99999;
}

.dark-layout {
	.header-date-total {
		background-color: #283046;
	}
}
</style>
