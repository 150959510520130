<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <b-row>
                <b-col
                  cols="6"
                  sm="6"
                  class="
                    d-flex
                    align-items-center
                    justify-content-center justify-content-sm-start
                  "
                >
                  <span class="text-muted" style="font-size: 20px"
                    >Mostrando {{ paginate.start_page }} a
                    {{ paginate.end_page }} de
                    {{ paginate.total_data }} registros</span
                  >
                </b-col>
                <b-col
                  cols="6"
                  sm="6"
                  class="
                    d-flex
                    align-items-center
                    justify-content-end justify-content-sm-start
                  "
                >
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary"
                    @click="prev(paginate.start_page)"
                    class="btn-icon rounded-circle"
                    :disabled="paginate.start_page == 1"
                    style="margin-right: 5px"
                  >
                    <feather-icon icon="ChevronLeftIcon" />
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary"
                    @click="next(paginate.next_page)"
                    class="btn-icon rounded-circle"
                    :disabled="paginate.start_page == paginate.end_page"
                  >
                    <feather-icon icon="ChevronRightIcon" />
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary"
                    @click="resetSearch"
                    class="btn-icon rounded-circle ml-1"
                  >
                    <feather-icon icon="RefreshCcwIcon" />
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </div>
          <template v-for="postura in arrayRegisteredEggsByMadrilla">
            <div class="table-responsive" :key="postura.id">
              <div class="card">
                <div class="card-body">
                  <span
                    ><h4 class="title-module">
                      Postura {{ postura.id }}:
                    </h4> </span
                  ><span
                    >{{ postura.madrilla_alias }} |
                    {{ postura.padrillo_alias }}</span
                  >
                  <b-badge
                    :variant="
                      postura.status == 'ANULADA'
                        ? 'danger'
                        : postura.status == 'PENDIENTE'
                        ? 'warning'
                        : postura.status == 'EN PROCESO'
                        ? 'success'
                        : 'primary'
                    "
                  >
                    {{ postura.status }}
                  </b-badge>
                  <div class="table-responsive mt-1">
                    <table class="table mt-1">
                      <thead class="text-center">
                        <th scope="col">Fecha</th>
                        <th scope="col">Cantidad de huevos</th>
                        <!-- <th scope="col">Creado por</th> -->
                      </thead>
                      <tbody>
                        <tr v-if="loading">
                          <td colspan="11" class="text-center">
                            <b-spinner variant="primary" />
                          </td>
                        </tr>
                        <template v-else>
                          <tr
                            v-for="eggRegistered in postura.json_data"
                            :key="eggRegistered.id"
                          >
                            <td data-label="Fecha" class="text-center">
                              {{ eggRegistered.date | myGlobalDayShort }}
                            </td>
                            <td
                              class="align-items-center text-center"
                              data-label="Cantidad de huevos"
                              v-if="eggRegistered.ep_deleted_at"
                            >
                              <span> {{ eggRegistered.quantity }}</span>
                              <span
                                :class="[
                                  'badge',
                                  'bg-danger',
                                  'text-white',
                                  'pr-3',
                                  'pl-3',
                                ]"
                                >ELIMINADO</span
                              >
                            </td>
                            <td
                              data-label="Cantidad de huevos"
                              class="text-center"
                              v-else
                            >
                              {{ eggRegistered.quantity }}
                            </td>
                          </tr>
                          <tr>
                            <td class="text-center">
                              <strong>Total: </strong>
                            </td>
                            <td class="text-center">
                              <strong>{{ postura.total_quantity }}</strong>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <hr />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Ripple from "vue-ripple-directive";
export default {
  props: {
    madrillaPlate: String,
    idMadrilla: Number,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      arrayRegisteredEggsByMadrilla: [],
      dato1: "",
      dato2: "",
      paginate: {
        orderby: this.dato2 == null ? 3 : this.dato2,
        order: this.dato1 == null ? "desc" : this.dato1,
        start_page: "",
        end_page: "",
        total_data: "",
        perpage: "",
        next_page: "",
        search_input: "",
      },
      posturaId: null,
      posturaComment: null,
      modalActivatingPostura: false,
      loading: false,
    };
  },
  mounted() {
    this.getRegisteredEggsByMadrillaDetail(null, null);
  },
  methods: {
    getRegisteredEggsByMadrillaDetail(dato1, dato2) {
      this.loading = true;
      this.dato1 = dato1;
      this.dato2 = dato2;
      axios
        .post("/api/postura/registered-eggs-madrilla-detail", {
          orderby: dato1 == null ? "desc" : dato1,
          order: dato2 == null ? 3 : dato2,
          campo:
            this.paginate.search_input == ""
              ? null
              : this.paginate.search_input,
          madrilla_plate: this.madrillaPlate,
          id_madrilla: this.idMadrilla,
        })
        .then((response) => {
          if (response.status == 200) {
            this.arrayRegisteredEggsByMadrilla = response.data.data;
            this.paginate.start_page = response.data.current_page;
            this.paginate.perpage = response.data.per_page;
            this.paginate.next_page = this.paginate.start_page + 1;
            this.paginate.end_page = response.data.last_page;
            this.paginate.total_data = response.data.total;
            this.loading = false;
            //this.loadingData = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    prev(start_p) {
      this.loading = true;
      var newval = start_p - 1;
      axios
        .post("/api/postura/registered-eggs-madrilla-detail?page=" + newval, {
          orderby: this.dato1 == null ? "desc" : this.dato1,
          order: this.dato2 == null ? 3 : this.dato2,
          campo:
            this.paginate.search_input == ""
              ? null
              : this.paginate.search_input,
          madrilla_plate: this.madrillaPlate,
        })
        .then((response) => {
          if (response.status == 200) {
            this.arrayRegisteredEggsByMadrilla = response.data.data;
            this.paginate.start_page = response.data.current_page;
            this.paginate.perpage = response.data.per_page;
            this.paginate.next_page = this.paginate.start_page + 1;
            this.paginate.end_page = response.data.last_page;
            this.paginate.total_data = response.data.total;
            if (this.paginate.start_page == this.paginate.end_page) {
              this.paginate.next_page = this.paginate.start_page;
            } else {
              this.paginate.next_page = this.paginate.start_page + 1;
            }
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    next(next) {
      this.loading = true;
      axios
        .post("/api/postura/registered-eggs-madrilla-detail?page=" + next, {
          orderby: this.dato1 == null ? "desc" : this.dato1,
          order: this.dato2 == null ? 3 : this.dato2,
          campo:
            this.paginate.search_input == ""
              ? null
              : this.paginate.search_input,
          madrilla_plate: this.madrillaPlate,
        })
        .then((response) => {
          if (response.status == 200) {
            this.arrayRegisteredEggsByMadrilla = response.data.data;
            this.paginate.start_page = response.data.current_page;
            this.paginate.perpage = response.data.per_page;
            this.paginate.next_page = this.paginate.start_page + 1;
            this.paginate.end_page = response.data.last_page;
            this.paginate.total_data = response.data.total;
            if (this.paginate.start_page == this.paginate.end_page) {
              this.paginate.next_page = this.paginate.start_page;
            } else {
              this.paginate.next_page = this.paginate.start_page + 1;
            }
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    resetSearch() {
      this.paginate.search_input = "";
      this.getRegisteredEggsByMadrillaDetail(null, null);
    },
    moment: function () {
      return moment();
    },
  },
  watch: {
    "paginate.search_input"() {
      this.getRegisteredEggsByMadrillaDetail(null, null);
    },
  },
};
</script>

<style>
</style>
