var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"body-class":"m-0 p-0","title-class":"py-25","scrollable":"","hide-footer":""},on:{"hide":function($event){return _vm.$emit('refresh')}},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('strong',[_vm._v("HUEVOS REGISTRADOS POR MADRILLAS: ")]),_c('span',[_vm._v(_vm._s(_vm.modalTitle))])]},proxy:true}]),model:{value:(_vm.modalEggByIdEgg.show),callback:function ($$v) {_vm.$set(_vm.modalEggByIdEgg, "show", $$v)},expression:"modalEggByIdEgg.show"}},[_c('b-table',{attrs:{"fields":_vm.fields,"items":_vm.items,"foot-clone":"","responsive":""},scopedSlots:_vm._u([{key:"cell(madrilla)",fn:function(ref){
var item = ref.item;
return [_c('SpecimenPlate',{attrs:{"specimen":{
						plate: item.madrilla.plate,
						alias: item.madrilla.alias,
						id: item.madrilla.id,
						thumb: item.madrilla.thumb,
						image: item.madrilla.image,
					}}})]}},{key:"cell(quantity)",fn:function(ref){
					var item = ref.item;
return [_c('div',[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(item.quantity))])])]}},{key:"foot(madrilla)",fn:function(){return [_c('p',{staticClass:"mb-0"})]},proxy:true},{key:"foot(quantity)",fn:function(){return [_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.sumNumbers(_vm.items, "quantity")))])]},proxy:true}])})],1),(_vm.clickImage.open)?_c('SlidersImageVideoVue',{attrs:{"url":_vm.clickImage.url,"type":_vm.clickImage.type,"text":_vm.clickImage.text,"text_url":_vm.clickImage.text_url,"open":_vm.clickImage.open,"idSpecimen":_vm.clickImage.idSpecimen},on:{"refreshSlider":_vm.refresh}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }