<template>
	<div>
		<b-modal
			body-class="m-0 p-0"
			@hide="$emit('refresh')"
			v-model="modalEggByIdEgg.show"
			title-class="py-25"
			scrollable
			hide-footer
		>
			<template #modal-title>
				<strong>HUEVOS REGISTRADOS POR MADRILLAS: </strong>
				<span>{{ modalTitle }}</span>
			</template>
			<b-table :fields="fields" :items="items" foot-clone responsive>
				<template #cell(madrilla)="{ item }">
					<SpecimenPlate
						:specimen="{
							plate: item.madrilla.plate,
							alias: item.madrilla.alias,
							id: item.madrilla.id,
							thumb: item.madrilla.thumb,
							image: item.madrilla.image,
						}"
					/>
				</template>

				<template #cell(quantity)="{ item }">
					<div>
						<p class="mb-0">{{ item.quantity }}</p>
					</div>
				</template>

				<template #foot(madrilla)>
					<p class="mb-0"></p>
				</template>

				<template #foot(quantity)>
					<p class="mb-0">{{ sumNumbers(items, "quantity") }}</p>
				</template>
			</b-table>
		</b-modal>

		<SlidersImageVideoVue
			:url="clickImage.url"
			:type="clickImage.type"
			:text="clickImage.text"
			:text_url="clickImage.text_url"
			:open="clickImage.open"
			:idSpecimen="clickImage.idSpecimen"
			v-if="clickImage.open"
			@refreshSlider="refresh"
		/>
	</div>
</template>

<script>
import eggService from "@/services/egg.service";
import SlidersImageVideoVue from "../../header-bookmarks/SlidersImageVideo.vue";
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue";

export default {
	name: "RegisterEggsByIdEgg",
	props: {
		selectIdEggs: Object,
		month: String,
		dateIn: String,
		dateFn: String,
		// Para traer huevos de la hora especifica
		dateOnly: String,
		dayOnly: [String, Number],
		modalTitle: String
	},
	components: {
		SlidersImageVideoVue,
		SpecimenPlate,
	},
	data() {
		return {
			modalEggByIdEgg: {
				show: false,
				title: null,
			},
			fields: [
				{ key: "madrilla", label: "Madrilla", class: "text-center" },
				{ key: "quantity", label: "Cantidad", class: "text-center" },
			],
			items: [],
			clickImage: {
				url: null,
				type: "image",
				text: null,
				text_url: null,
				open: false,
			},
		};
	},
	async mounted() {
		this.modalEggByIdEgg.show = true;
		await this.init();

		this.modalEggByIdEgg.title = this.selectIdEggs ? `${this.selectIdEggs.day} de ${this.month}` : `${this.month}`;
	},
	computed: {
		sumNumbers: () => (item, key) => {
			return item.reduce(function (previousValue, currentValue) {
				return previousValue + parseInt(currentValue[key]);
			}, 0);
		},
	},
	methods: {
		async init() {
			this.isPreloading();
			const resp = await eggService.getEggsByIdEggs({
				idEggsIn: this.selectIdEggs ? this.selectIdEggs.id : null,
				dateIn: this.dateIn && this.dateFn ? this.dateIn : null,
				dateFn: this.dateFn && this.dateFn ? this.dateFn : null,
				dateOnly: this.dateOnly ? this.dateOnly : '',
				dayOnly: this.dayOnly ? this.dayOnly : ''
			});
			this.items = resp;
			this.isPreloading(false);
		},
		refresh() {
			this.clickImage.open = false;
			this.clickImage.text_url = null;
			this.clickImage.type = null;
			this.clickImage.text = item.plate;
			this.clickImage.url = null;
		},
	},
};
</script>

<style lang="scss"></style>
