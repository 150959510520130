<template>
	<div class="container-register-eggs">
		<div class="card p-2">
			<b-row>
				<b-col cols="12" md="8">
					<b-row>
						<b-col cols="12" md="3" class="mb-1">
							<v-select
								v-model="season"
								label="season"
								:options="seasons"
								:clearable="false"
								:reduce="(opt) => opt.id"
								style="max-width: 150px !important; min-width: 90px !important"
								@input="init"
							/>
						</b-col>
						<b-col cols="12" md="6">
							<div class="d-flex align-items-center">
								<p class="mb-0">Ver por:</p>
								<b-button @click="clickModalViewMadrilla" variant="primary" class="ml-1">
									<span class="text-nowrap">Madrilla</span>
								</b-button>
							</div>
						</b-col>
					</b-row>
				</b-col>
				<b-col cols="12" md="4" class="d-flex justify-content-end align-items-center">
					<button @click="openModalRecomendation" class="relative btn btn-primary mt-1">
						Huevos del aire
						<p v-if="eggsToAir > 0" class="bg-danger icon-btn mb-0">
							{{ eggsToAir }}
						</p>
					</button>
				</b-col>
			</b-row>
			<div class="mt-2 w-full">
				<div class="table-responsive">
					<b-table :fields="fields" :items="items" foot-clone responsive>
						<template #cell(month)="{ item }">
							{{ item.month_name | MonthName }}
						</template>

						<template #cell(eggs)="{ item }">
							<p
								class="mb-0 text-primary font-weight-bolder cursor-pointer"
								@click="openEggsByMonth(item)"
							>
								{{ yearSelect }} {{ item.eggs }}
							</p>
						</template>

						<template #cell(madrillas)="{ item }">
							<p
								@click="clickMadrillas(item)"
								class="mb-0 text-primary font-weight-bolder cursor-pointer"
							>
								{{ item.madrillas }}
							</p>
						</template>

						<template #cell(last_day)="{ item }">
							{{ item.last_day | myGlobalDayShort }}
						</template>

						<template #foot(month)="{}">
							<span>TOTAL</span>
						</template>
						<template #foot(eggs)="{}">
							{{ totalEggs }}
						</template>
						<template #foot(madrillas)="{}">
							<span v-if="items.length">{{ items[0].total_madrillas }}</span>
						</template>
						<template #foot(average)="{}">
							{{ totalAverage }}
						</template>
						<template #foot(daily_average)="{}">
							{{ totalDailyAverage }}
						</template>
						<template #foot(last_day)>
							<span></span>
						</template>
					</b-table>
				</div>
			</div>
		</div>

		<div>
			<div class="card p-1">
				<b-button
					variant="primary"
					:block="$store.getters['app/currentBreakPoint'] === 'xs'"
					:class="['d-inline-block mr-1', $store.getters['app/currentBreakPoint'] === 'xs' ? 'mt-1' : '']"
					@click="openRegisterEggsModal()"
				>
					<span class="text-nowrap">Agregar huevos</span>
				</b-button>
			</div>
			<EggsAvailable :start_date="start_date" :end_date="end_date" />
		</div>

		<b-modal
			title="Detalle huevos fecha"
			hide-footer
			v-model="dateDetailModal"
			size="xl"
			ok-only
			ok-variant="secondary"
			ok-title="Cerrar"
		>
			<registro-huevos-fecha-detail :eggsId="selectedEgg.id"></registro-huevos-fecha-detail>
		</b-modal>

		<RegistroHuevosCreate v-if="modalAddEggs" @hidden="closeRegisterEggsModal" />

		<!-- VER POR MADRILLAS -->
		<b-modal
			v-if="modalViewMadrillas.show"
			v-model="modalViewMadrillas.show"
			:title="modalViewMadrillas.title"
			size="xl"
			body-class="m-0 p-0"
			ok-only
			ok-variant="secondary"
			ok-title="Cerrar"
			scrollable
		>
			<RegistroMadrilla :start_date="start_date" :end_date="end_date" />
		</b-modal>

		<EggsByMonth v-if="selectMonth" @refresh="refresh" :selectMonth="selectMonth" :yearSelect="yearSelect" />

		<RegisterEggsByIdEgg
			v-if="dateIn && dateFn"
			:dateIn="dateIn"
			:dateFn="dateFn"
			@refresh="refresh2"
			:month="nameMonth | MonthName"
			:modal-title="currentModalTitle"
		/>

		<b-sidebar
			v-model="viewModalRecomendation.show"
			:title="viewModalRecomendation.title"
			size="xmd"
			scrollable
			hide-footer
			shadow
			header-class="py-1 pr-0  bg-transparent"
			body-class="p-0"
			width="90%"
			v-if="viewModalRecomendation.show"
		>
			<div class="card p-2 p-md-4">
				<RecommendedMadrillas @refresh="initCounterMadrillasRecomended" :eggs="eggsToAir" />
			</div>
		</b-sidebar>
	</div>
</template>
<script>
import Ripple from "vue-ripple-directive";
import RegistroHuevosCreate from "./RegistroHuevosCreate";
import EggsByMonth from "./EggsByMonth.vue";
import RegisterEggsByIdEgg from "./RegisterEggsByIdEgg.vue";
import EggsAvailable from "./EggsAvailable.vue";
import RegistroMadrilla from "./RegistroMadrilla.vue";
import moment from "moment";
import eggService from "@/services/egg.service";
import { bus } from "@/main";
import RecommendedMadrillas from "../posturas/madrillas/RecommendedMadrillas.vue";

import { mapState, mapMutations } from "vuex";

export default {
	props: {
		status: Number,
	},
	directives: {
		Ripple,
	},
	components: {
		RegistroHuevosCreate,
		EggsByMonth,
		RegisterEggsByIdEgg,
		EggsAvailable,
		RegistroMadrilla,
		RecommendedMadrillas,
	},
	created() {
		bus.$on("reload-eggs-available", async () => {
			await this.init();
		});
	},
	destroyed() {
		bus.$off("reload-eggs-available");
	},
	async mounted() {
		this.season = this.seasons[0].id;

		await this.initCounterMadrillasRecomended();
		await this.init();
		console.log("cd", this.yearSelect);
	},
	computed: {
		...mapState("headers", ["eggsFound"]),
	},
	data() {
		return {
			sortBy: "egg_postura_date",
			sortDesc: true,
			arrayColumns: [
				{
					key: "egg_postura_date",
					label: "Fecha",
					class: "text-center",
					sortable: true,
					visible: true,
				},
				{
					key: "total_eggs_quantity",
					label: "Huevos agregados",
					class: "text-center",
					sortable: true,
					visible: true,
				},
				{
					key: "description",
					label: "Descripción",
					class: "text-center",
					sortable: true,
					visible: true,
				},
				{
					key: "created_at",
					label: "Creado por",
					class: "text-center",
					sortable: true,
					visible: true,
				},
				{
					key: "deleted_by_name",
					label: "Eliminado por",
					class: "text-center",
					sortable: true,
					visible: this.status == 2 ? true : false,
				},
				{
					key: "actions",
					label: "Acciones",
					class: "text-center ",
					visible: true,
				},
			],
			search_input: "",
			orderby: this.dato2 == null ? 3 : this.dato2,
			order: this.dato1 == null ? "desc" : this.dato1,
			start_page: "",
			end_page: "",
			total_data: "",
			perpage: 100,
			next_page: "",
			current_page: 1,
			to_page: "",
			dato1: "",
			dato2: "",
			loadingData: false,
			isBusy: false,
			perPageOptions: [10, 25, 50, 100],
			chickenleg: null,
			loading: false,
			createDeadsPageController: false,
			deadSpecimenDetail: false,
			selectedDeadSpecimenId: null,
			modalReasonController: false,
			selectedReason: {},
			madrillaDetailModal: false,
			selectedDate: null,
			selectedEgg: {
				id: null,
			},
			dateDetailModal: false,
			modalAddEggs: false,
			show: false,
			scrollableActive: true,
			fields: [
				{ key: "month", label: "Mes", class: "text-center" },
				{ key: "eggs", label: "Registrados", class: "text-center" },
				{ key: "madrillas", label: "Madrillas", class: "text-center" },
				{ key: "average", label: "Promedio por madrilla", class: "text-center" },
				{ key: "daily_average", label: "Promedio diario", class: "text-center" },
				{ key: "last_day", label: "Ultimo registro", class: "text-center" },
			],
			items: [],
			yearSelect: null,
			newMad: [],
			modalEggByMonth: {
				month: null,
				show: false,
			},
			selectMonth: null,
			dateIn: null,
			dateFn: null,
			nameMonth: null,
			modalViewMadrillas: {
				title: null,
				show: false,
			},
			totalEggs: 0,
			totalAverage: 0,
			totalDailyAverage: 0,
			currentModalTitle: null,
			viewModalRecomendation: {
				show: false,
				title: null,
				counter: null,
			},
			eggsToAir: 0,
			season: null,
			start_date: null,
			end_date: null,
		};
	},
	methods: {
		async init() {
			const { start, end } = this.seasons.find((season) => season.id == this.season);

			this.start_date = start;
			this.end_date = end;

			// return ;
			this.isPreloading();
			const { data } = await eggService.getEggsByMonth({ dateFn: end, dateIn: start });
			this.items = data;
			this.sumTotalValues();
			this.isPreloading(false);
			this.show = false;
		},
		sumTotalValues() {
			const itemsCount = this.items.length;
			this.totalAverage = 0;
			this.totalDailyAverage = 0;

			for (let i = 0; i < itemsCount; i++) {
				this.totalEggs += parseInt(this.items[i].eggs);
				this.totalAverage += parseFloat(this.items[i].average);
				this.totalDailyAverage += parseFloat(this.items[i].daily_average);
			}

			this.totalAverage = Number(this.totalAverage / itemsCount).toFixed(2);
			this.totalDailyAverage = Number(this.totalDailyAverage / itemsCount).toFixed(2);
		},
		async clickmonth(item) {
			this.modalEggByMonth.month = item.month;
			this.modalEggByMonth.show = true;
			this.selectMonth = item;
		},
		async openEggsByMonth(item) {
			this.modalEggByMonth.title = "";
			this.modalEggByMonth.show = true;
			this.selectMonth = item.month_name;
			// this.yearSelect = item.month_name
			// console.log('sd', item.month_name.split('-')[0]);
		},
		closeRegisterEggsModal() {
			this.modalAddEggs = false;
		},

		openRegisterEggsModal() {
			this.modalAddEggs = true;
		},
		refresh() {
			this.selectMonth = null;
		},
		clickMadrillas(item) {
			let getNumberMonth = parseInt(item.month_name.split("-")[1]);
			let getYear = parseInt(item.month_name.split("-")[0]);
			const lastDay = new Date(this.yearSelect, getNumberMonth, 0).getDate();

			const dateIn = `${getYear}-${getNumberMonth < 10 ? `0${getNumberMonth}` : getNumberMonth}-01`;
			const dateFn = `${getYear}-${getNumberMonth < 10 ? `0${getNumberMonth}` : getNumberMonth}-${
				lastDay < 10 ? `0${lastDay}` : lastDay
			}`;

			console.log("return", dateIn, dateFn);

			this.nameMonth = item.month_name;
			this.currentModalTitle =
				String(
					moment(`${getYear}-${getNumberMonth < 10 ? `0${getNumberMonth}` : getNumberMonth}-01`).format(
						"MMMM"
					)
				)
					.substring(0, 3)
					.toUpperCase() +
				" " +
				getYear;
			this.dateIn = dateIn;
			this.dateFn = dateFn;
		},

		formatMoment(month) {
			moment.locale("es");
			const monthFormated = String(moment(month).format("MMMM")).substring(0, 3).toUpperCase();
			return (
				" " +
				monthFormated +
				" " +
				([1, 2].includes(parseInt(month.split("-")[1])) ? this.yearSelect + 1 : this.yearSelect)
			);
		},
		refresh2() {
			this.dateIn = null;
			this.dateFn = null;
		},
		clickModalViewMadrilla() {
			this.modalViewMadrillas.show = true;
			this.modalViewMadrillas.title = "Huevos por Madrillas";
		},
		openModalRecomendation() {
			this.viewModalRecomendation.show = true;
			this.viewModalRecomendation.title = "Huevos del aire";
		},
		async initCounterMadrillasRecomended() {
			this.isPreloading();

			const { count } = await eggService.counterMadrillasRecomended();
			this.eggsToAir = count;
			this.isPreloading(false);
		},
	},
};
</script>

<style lang="scss" scoped>
.per-page-selector {
	width: 90px;
}

td.div {
	width: 100% !important;
}

@media (max-width: 960px) {
	.column-table {
		display: flex;
		flex-direction: column;
	}
}
.bg-danger {
	height: 25px;
	width: 25px;
	border-radius: 50%;
	position: absolute;
	top: 0;
	right: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>
