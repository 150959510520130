<template>
	<div>
		<RegistroHuevosFecha :status="1" />
		<!-- <b-tabs content-class="mt-2" nav-wrapper-class="card pt-1 pr-1 pl-1" lazy v-model="tabIndex">
			<b-tab title="Fecha">
			</b-tab>
			<b-tab title="Madrillas">
				<RegistroMadrilla />
			</b-tab>
		</b-tabs> -->
	</div>
</template>

<script>
import RegistroMadrilla from "./RegistroMadrilla.vue";
import RegistroHuevosFecha from "./RegistroHuevosFecha.vue";
import EggsAvailable from "./EggsAvailable.vue";
export default {
	components: {
		RegistroMadrilla,
		RegistroHuevosFecha,
		EggsAvailable,
	},
	data() {
		return {
			tabIndex: 0,
		};
	},
};
</script>

<style lang="scss">
.container-register-eggs {
	display: grid;
	grid-template-columns: 1fr;
	gap: 2rem;
	@media screen and (min-width: 1000px) {
		grid-template-columns: 2fr 1fr;
	}
	@media screen and (min-width: 1200px) {
		grid-template-columns: 4fr 1fr;
	}
}
</style>
