<template>
	<div>
		<b-card no-body class="mb-1">
			<div class="m-2">
				<div class="mb-2">
					<v-select
						v-model="yearMadrilla"
						:options="seasons"
						:clearable="false"
						label="season"
						:reduce="(option) => option.id"
						:style="{ maxWidth: '12ch', minWidth: '18ch' }"
						@input="setSeasonsMadrilla($event)"
					/>
				</div>
				<div class="mb-2">
					<b-row>
						<b-col
							cols="12"
							sm="6"
							class="d-flex align-items-center justify-content-center justify-content-sm-start"
						>
							<span class="text-muted"
								>Mostrando {{ start_page }} a {{ to_page }} de {{ total_data }} registros</span
							>
						</b-col>
						<b-col
							cols="12"
							sm="6"
							class="d-flex align-items-center justify-content-center justify-content-sm-end"
						>
							<b-pagination
								v-model="current_page"
								:total-rows="total_data"
								:per-page="perpage"
								first-number
								last-number
								class="mb-0 mt-1 mt-sm-0"
								prev-class="prev-item"
								next-class="next-item"
							>
								<template #prev-text>
									<feather-icon icon="ChevronLeftIcon" size="18" />
								</template>
								<template #next-text>
									<feather-icon icon="ChevronRightIcon" size="18" />
								</template>
							</b-pagination>
						</b-col>
					</b-row>
				</div>
				<b-row>
					<!-- Per Page -->
					<b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
						<label>Mostrar</label>
						<v-select
							v-model="perpage"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
							@input="getMadrillas()"
						/>
						<label class="mr-2">registros</label>
						<feather-icon class="cursor-pointer" icon="RefreshCcwIcon" size="20" @click="getMadrillas()" />
					</b-col>

					<b-col cols="12" md="2"></b-col>
					<!-- Search -->
					<b-col cols="12" md="4">
						<div class="d-flex align-items-center justify-content-end">
							<b-form-input
								v-model="search_input"
								class="d-inline-block mr-1"
								placeholder="Placa / Alias"
								@keyup.enter="getMadrillas()"
								debounce="500"
							/>
						</div>
					</b-col>
				</b-row>
			</div>
			<div class="d-flex flex-wrap justify-content-center content-madrillas">
				<template v-if="arrayMadrillas.length > 0">
					<div
						class="card-specimen bg-white"
						v-for="(item, index) in arrayMadrillas"
						:key="`${index}-madrilla`"
					>
						<div class="d-flex align-items-center">
							<SpecimenPlate
								setWidth="auto"
								:specimen="{
									plate: item.placa_madrilla,
									alias: item.madrilla_alias,
									id: item.madrilla_id,
									thumb: item.thumb,
									image: item.photo,
								}"
							/>
							<h3
								class="text-primary cursor-pointer text-center"
								style="width: 80px"
								@click="openEggsByMadrilla(item)"
							>
								<u>{{ item.suma }}</u>
							</h3>
						</div>
					</div>
				</template>
				<template v-else>
					<h3 class="py-2">No se encontraron coincidencias</h3>
				</template>
			</div>
			<div class="mx-2 my-2">
				<b-row>
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start"
					>
						<span class="text-muted"
							>Mostrando {{ start_page }} a {{ to_page }} de {{ total_data }} registros</span
						>
					</b-col>
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end"
					>
						<b-pagination
							v-model="current_page"
							:total-rows="total_data"
							:per-page="perpage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
							@input="getMadrillas()"
						>
							<template #prev-text>
								<feather-icon icon="ChevronLeftIcon" size="18" />
							</template>
							<template #next-text>
								<feather-icon icon="ChevronRightIcon" size="18" />
							</template>
						</b-pagination>
					</b-col>
				</b-row>
			</div>
		</b-card>
		<b-modal
			v-model="modalReasonController"
			size="lg"
			:title="`${selectedReason ? 'Actualizar' : 'Registrar'} motivo`"
			hide-footer
			scrollable
		>
			<create-reason :reason="selectedReason" @closeModal="closeModal"></create-reason>
		</b-modal>
		<!-- <b-modal title="Detalle  huevos madrilla" hide-footer v-model="madrillaDetailModal" size="xl" scrollable>
      <registro-madrilla-detail
        :madrillaPlate="selectedMadrilla.placa_madrilla"
        :idMadrilla="selectedMadrilla.madrilla_id"
      ></registro-madrilla-detail>
    </b-modal> -->

		<EggsByMadrilla
			:start_date="start_date_madrilla"
			:end_date="end_date_madrilla"
			:madrilla="madrilla"
			v-if="controllerEggsByMadrilla"
			@hidden="closeEggsByMadrilla()"
		/>
	</div>
</template>
<script>
import vSelect from "vue-select";
import axios from "@/axios";
import Ripple from "vue-ripple-directive";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import RegistroMadrillaDetail from "./RegistroMadrillaDetail.vue";
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue";
import EggsByMadrilla from "./EggsByMadrilla";
import moment from "moment";

export default {
	name: "RegistroMadrilla",
	directives: {
		Ripple,
	},
	props: {
		start_date: {
			type: String,
		},
		end_date: {
			type: String,
		},
	},
	components: {
		SpecimenPlate,
		vSelect,
		AppCollapse,
		AppCollapseItem,
		RegistroMadrillaDetail,
		EggsByMadrilla,
	},
	async mounted() {
		this.start_date_madrilla = this.start_date;
		this.end_date_madrilla = this.end_date;
		const { id } = this.seasons.find((s) => s.start == this.start_date);
		this.yearMadrilla = id;
		await this.getMadrillas();
		this.counter++;
	},
	watch: {
		yearMadrilla(newValue, oldValue) {
			if (newValue !== oldValue && this.counter > 0) {
				this.getMadrillas();
			}
		},
	},
	data() {
		return {
			search_input: "",
			start_page: "",
			end_page: "",
			total_data: "",
			perpage: 100,
			next_page: "",
			current_page: 1,
			to_page: "",
			loadingData: false,
			isBusy: false,
			perPageOptions: [10, 25, 50, 100],
			chickenleg: null,
			loading: false,
			createDeadsPageController: false,
			deadSpecimenDetail: false,
			selectedDeadSpecimenId: null,
			modalReasonController: false,
			selectedReason: {},
			madrillaDetailModal: false,
			selectedMadrilla: {
				madrilla_alias: null,
				placa_madrilla: null,
			},
			modalAddEggs: false,
			show: false,
			scrollableActive: true,
			years: [],
			yearMadrilla: null,
			controllerEggsByMadrilla: false,
			madrilla: {},
			arrayMadrillas: [],
			counter: 0,
			start_date_madrilla: null,
			end_date_madrilla: null,
		};
	},
	methods: {
		async getMadrillas() {
			this.isPreloading();
			// TODO: PASARLO A UN SERVICIO
			const url = "/api/egg/show-madrillas-with-eggs";
			const { data } = await axios.get(url, {
				params: {
					orderby: "asc",
					order: "placa_madrilla",
					campo: this.search_input,
					perpage: this.perpage,
					page: this.current_page,
					dateIn: this.start_date_madrilla,
					dateFn: this.end_date_madrilla,
				},
			});
			this.arrayMadrillas = data.data;
			this.start_page = data.from;
			this.current_page = data.current_page;
			this.perpage = data.per_page;
			this.next_page = this.start_page + 1;
			this.end_page = data.last_page;
			this.total_data = data.total;
			this.to_page = data.to;
			this.isPreloading(false);
		},
		editReason(item) {
			this.selectedReason = item;
			this.modalReasonController = true;
		},
		deleteReason(item) {
			this.$swal({
				title: "Estas seguro?",
				text: "¡No podrás revertir esto!",
				icon: "warning",
				showCancelButton: true,
				cancelButtonText: "Cancelar",
				confirmButtonText: "Confirmar",
				customClass: {
					cancelButton: "btn btn-outline-danger ml-1",
					confirmButton: "btn btn-primary",
				},
				buttonsStyling: false,
			}).then((result) => {
				if (result.value) {
					axios
						.post("/api/reasons/delete", {
							deleted_by: this.currentUser.user_id,
							reason_id: item.id,
						})
						.then((res) => {
							this.showToast(
								"success",
								"top-right",
								"Felicidades",
								"CheckIcon",
								"El motivo ha sido eliminado con éxito!"
							);
							// this.$refs.refRegisterEggs.refresh();
						});
				}
			});
		},
		openRegisterReason() {
			this.selectedReason = null;
			this.modalReasonController = true;
		},
		showToast(variant, position, title, icon, text) {
			this.$toast(
				{
					component: ToastificationContent,
					props: {
						title,
						icon,
						text,
						variant,
					},
				},
				{
					position,
				}
			);
		},
		closeModal() {
			this.modalReasonController = false;
			// this.$refs.refRegisterEggs.refresh();
		},
		openMadrillaDetailModal(madrilla) {
			this.selectedMadrilla = madrilla;
			this.madrillaDetailModal = true;
		},
		generateYears() {
			const years = [];
			for (let i = -2; i < 1; i++) {
				const current = {
					year: moment().add(`${i}`, "Y").format("YYYY"),
					label: moment().add(`${i}`, "Y").format("YYYY"),
				};
				years.push(current);
			}
			this.years = years.sort().reverse();
		},
		openEggsByMadrilla(item) {
			const { madrilla_alias, madrilla_id, placa_madrilla } = item;
			this.madrilla = {
				id: madrilla_id,
				plate: placa_madrilla,
				alias: madrilla_alias,
			};
			this.controllerEggsByMadrilla = true;
		},
		closeEggsByMadrilla() {
			this.controllerEggsByMadrilla = false;
			this.madrilla_id = null;
		},
		setSeasonsMadrilla(id) {
			const { start, end } = this.seasons.find((season) => season.id == id);
			this.start_date_madrilla = start;
			this.end_date_madrilla = end;
		},
	},
};
</script>

<style lang="scss" scoped>
.content-madrillas {
	background-color: #f1f1f1;
}
.card-specimen {
	box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
	width: 250px;
	margin: 10px;
	padding: 10px;

	.specimen-content .specimen {
		padding: 0px;
		// display: flex;
		// align-items: center;
		// justify-content: space-between;
		width: auto !important;
	}
}
.per-page-selector {
	width: 90px;
}
td.div {
	width: 100% !important;
}
@media (max-width: 960px) {
	.column-table {
		display: flex;
		flex-direction: column;
	}
}
</style>
