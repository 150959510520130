<template>
	<b-modal
		ref="modal-eggs-by-madrilla"
		@hidden="$emit('hidden')"
		:title="title"
		no-close-on-backdrop
		centered
		size="xmd"
		ok-only
		ok-variant="secondary"
		ok-title="Cerrar"
	>
		<b-table-simple class="text-center position-relative" sticky-header="60vh">
			<b-thead>
				<b-tr>
					<b-th class="bg-transparent border-0" colspan="3"></b-th>
					<b-th class="bg-primary text-white roundedTop" colspan="4">Huevos</b-th>
					<b-th class="bg-primary text-white roundedTop" colspan="2" variant="primary">Pollos</b-th>
					<b-th class="bg-transparent border-0" colspan="1"></b-th>
				</b-tr>
				<b-tr class="text-nowrap">
					<b-th>Padrillo</b-th>
					<b-th>Postura</b-th>
					<b-th>Fechas</b-th>
					<b-th>Registrados</b-th>
					<b-th>Ingresados</b-th>
					<b-th>Infertiles</b-th>
					<b-th>Quemados</b-th>
					<b-th>Nacidos</b-th>
					<b-th>No Nacidos</b-th>
					<b-th>Acciones</b-th>
				</b-tr>
			</b-thead>
			<b-tbody>
				<b-tr v-for="row in rows" :key="row.postura.id">
					<b-td>
						<SpecimenPlate :specimen="row.padrillo" />
					</b-td>
					<b-td>
						<span>{{ row.postura.code }}</span>
						<br />
						<b class="uppercase">{{ row.postura.status }}</b>
					</b-td>
					<b-td>
						<div class="mb-05" v-if="row.postura.start_date">
							<small>Inicio:</small>
							<br />
							{{ row.postura.start_date | myGlobalDayShort }}
						</div>
						<div v-if="row.postura.end_date">
							<small>Fin:</small>
							<br />
							{{ row.postura.end_date | myGlobalDayShort }}
						</div>
					</b-td>
					<b-td>
						<span v-if="row.registered == null">0</span>
						<span v-else>{{ row.registered }}</span>
					</b-td>
					<b-td>
						<span v-if="row.entered == null">0</span>
						<span v-else>{{ row.entered }}</span>
					</b-td>
					<b-td>
						<span v-if="row.infertile == null">0</span>
						<span v-else>{{ row.infertile }}</span>
					</b-td>
					<b-td>
						<span v-if="row.burned == null">0</span>
						<span v-else>{{ row.burned }}</span>
					</b-td>
					<b-td>
						<span v-if="row.borned == null">0</span>
						<span v-else>{{ row.borned }}</span>
					</b-td>
					<b-td>
						<span v-if="row.unborn == null">0</span>
						<span v-else>{{ row.unborn }}</span>
					</b-td>
					<b-td>
						<b-button
							class="btn-icon"
							variant="outline-info"
							@click=";(detail.info = row), (detail.show = !detail.show)"
						>
							<feather-icon icon="EyeIcon" />
						</b-button>
					</b-td>
				</b-tr>
				<b-tr v-if="rows.length == 0">
					<b-td colspan="10">No hay datos por mostrar</b-td>
				</b-tr>
			</b-tbody>
		</b-table-simple>
		<EggMadrillaDetailModal :show="detail.show" :info="detail.info" @closing="detail.show = false" />
	</b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal.js"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"

import EggMadrillaDetailModal from "./EggMadrillaDetailModal.vue"

export default {
	name: "EggsByMadrilla",
	props: {
		madrilla: {
			type: Object,
			required: true,
		},
		start_date: {
			type: String,
		},
		end_date: {
			type: String,
		},
	},
	mixins: [modalMixin],
	components: {
		SpecimenPlate,
		EggMadrillaDetailModal,
	},
	async mounted() {
		this.getRows()
		this.toggleModal("modal-eggs-by-madrilla")
	},
	destroyed() {
		this.rows = []
	},
	computed: {
		title() {
			return (
				this.madrilla.alias ? this.madrilla.alias + " | " + this.madrilla.plate : this.madrilla.plate
			).toUpperCase()
		},
	},
	data() {
		return {
			rows: [],
			detail: { show: false, info: {} },
		}
	},
	methods: {
		async getRows() {
			this.isPreloading()
			const {
				data: { data: response },
			} = await axios.get(`/api/postura/posturas-with-eggs-by-madrilla/${this.madrilla.id}`, {
				params: {
					start_date: this.start_date,
					end_date: this.end_date,
				},
			})
			// this.rows = response
			// const duplicateds = response.data.map((d) => d.postura.id)
			// const partial = response			// for (let idx = 0; idx < response.datax.length; idx++) {
			// 	const element = response.datax[idx]
			// 	if (!duplicateds.includes(element.postura.id)) {
			// 		partial.push(element)
			// 	}
			// }
			this.rows = response
			this.isPreloading(false)
		},
	},
}
</script>

<style>
.roundedTop {
	border: 2px solid white;
	border-radius: 1rem 1rem 0 0;
}
</style>
