<template>
	<b-modal
		centered
		no-close-on-backdrop
		hide-footer
		size="sm"
		:title="title"
		v-model="isActive"
		@hidden="handleHidden"
	>
		<b-overlay :show="isLoading">
			<div class="mb-2 text-center">
				<h3 class="m-0">
					<b-badge variant="primary">Total: {{ total }}</b-badge>
				</h3>
			</div>
			<b-table-simple sticky-header="60vh">
				<b-thead class="text-center text-nowrap">
					<b-tr>
						<b-th>Cantidad</b-th>
						<b-th>Fecha</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr v-for="(row, idx) in rows" :key="idx">
						<b-td>{{ row.ep_quantity }}</b-td>
						<b-td>{{ row.ep_created_at | myGlobalDayShort }}</b-td>
					</b-tr>
					<b-tr v-if="rows.length == 0">
						<b-td colspan="2">No hay datos por mostrar</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-overlay>
	</b-modal>
</template>

<script>
import axios from "@/axios"

export default {
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		title: "",
		rows: [],
		total: 0,
	}),
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.rows = []
			this.$emit("closing")
		},
		async getRows() {
			this.isLoading = true
			const { data } = await axios.get(`api/postura/get-input-eggs/${this.info.postura.id}`)
			this.rows = data.data
			this.total = this.rows.reduce((acc, pre) => acc + pre.ep_quantity, 0)
			this.isLoading = false
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.title = `Postura: ${this.info.postura.code} / Huevos por Fecha`
				this.getRows()
			}
		},
	},
}
</script>
