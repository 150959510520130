var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal-registro-huevos-create",attrs:{"title":"Agregar huevos","size":"lg","cancel-title":"Cancelar","no-close-on-backdrop":"","scrollable":""},on:{"hidden":function($event){return _vm.$emit('hidden')}},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('div',{staticClass:"d-flex justify-content-between w-100"},[(!_vm.show)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"secondary"},on:{"click":cancel}},[_c('span',[_vm._v("Cancelar")])]):_c('b-button',{attrs:{"variant":"secondary","disabled":""}},[_c('b-spinner',{attrs:{"small":""}}),_vm._v(" Cargando... ")],1),_c('div',[(!_vm.show)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"info"},on:{"click":function($event){return _vm.addPosturas()}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Agregar nuevo")])],1):_c('b-button',{staticClass:"mr-1",attrs:{"variant":"info","disabled":""}},[_c('b-spinner',{attrs:{"small":""}}),_vm._v(" Cargando... ")],1),(!_vm.show)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":function($event){return _vm.save()}}},[_c('span',[_vm._v("Guardar")])]):_c('b-button',{attrs:{"variant":"primary","disabled":""}},[_c('b-spinner',{attrs:{"small":""}}),_vm._v(" Cargando... ")],1)],1)],1)]}}])},[_c('div',{staticStyle:{"height":"100vh"}},[_c('validation-observer',{ref:"form"},[_c('b-row',{staticClass:"header-date-total"},[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Fecha","label-for":"date"}},[_c('b-form-datepicker',{attrs:{"max":new Date(),"id":"date","locale":"es","date-format-options":{
								year: 'numeric',
								month: 'numeric',
								day: 'numeric',
							},"placeholder":"Fecha"},model:{value:(_vm.registerEggsDate),callback:function ($$v) {_vm.registerEggsDate=$$v},expression:"registerEggsDate"}})],1)],1),_c('b-col',{staticClass:"d-flex justify-content-md-center justify-content-between align-items-center",attrs:{"cols":"12","md":"4"}},[_c('label',{staticClass:"mr-1"},[_vm._v("Cantidad de posturas")]),_c('h4',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.arrayMadrillasSelected.length))])]),_c('b-col',{staticClass:"d-flex justify-content-md-center justify-content-between align-items-center",attrs:{"cols":"12","md":"4"}},[_c('label',{staticClass:"mr-1"},[_vm._v("Total de huevos")]),_c('h4',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.totalRegisterEggs))])])],1),_vm._l((_vm.arrayPosturas),function(postura,index){return _c('b-row',{key:index},[_c('b-col',{staticClass:"d-flex align-items-center",attrs:{"cols":"12","lg":"8"}},[_c('b-form-group',{staticClass:"flex-grow-1",attrs:{"label":"Madrilla - Padrillo - Postura","label-for":'postura-madrilla-padrillo-' + index}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
							var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.arrayMadrillasByProcessingPosturas,"id":'postura-madrilla-padrillo-' + index,"clearable":false,"label":"placa_madrilla","reduce":function (option) { return option; },"filter":_vm.fuseSearch,"selectable":function (option) { return !option.isDisabled; },"autoscrollf":""},on:{"open":function($event){return _vm.scrollToBotom(index)},"input":function($event){return _vm.selectOnChange(postura, index)}},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
									var padrillo_alias = ref.padrillo_alias;
									var placa_padrillo = ref.placa_padrillo;
									var madrilla_alias = ref.madrilla_alias;
									var placa_madrilla = ref.placa_madrilla;
									var code = ref.code;
return [(madrilla_alias)?_c('span',[_vm._v(_vm._s(madrilla_alias)+" / ")]):_vm._e(),_vm._v(" ("+_vm._s(placa_madrilla)+") - "),(padrillo_alias)?_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(padrillo_alias)+" / ")]):_vm._e(),_vm._v(" ("+_vm._s(placa_padrillo)+") - ("+_vm._s(code)+") ")]}},{key:"option",fn:function(ref){
									var padrillo_alias = ref.padrillo_alias;
									var placa_padrillo = ref.placa_padrillo;
									var madrilla_alias = ref.madrilla_alias;
									var placa_madrilla = ref.placa_madrilla;
									var code = ref.code;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"text-center",staticStyle:{"min-width":"100px"}},[_c('div',[_vm._v(_vm._s(placa_madrilla))]),(madrilla_alias)?_c('span',[_c('small',[_vm._v(_vm._s(madrilla_alias)+" ")])]):_vm._e()]),_c('span',{staticClass:"font-weight-bold",staticStyle:{"font-size":"14px","margin":"0 10px"}},[_vm._v(" | ")]),_c('div',{staticClass:"text-center",staticStyle:{"min-width":"100px"}},[_c('div',[_vm._v(_vm._s(placa_padrillo))]),(padrillo_alias)?_c('span',[_c('small',[_vm._v(_vm._s(padrillo_alias)+" ")])]):_vm._e()]),_c('span',{staticClass:"font-weight-bold",staticStyle:{"font-size":"14px","margin":"0 10px"}},[_vm._v(" | ")]),_c('div',[_vm._v(" "+_vm._s(code)+" ")])])]}}],null,true),model:{value:(postura.postura_id),callback:function ($$v) {_vm.$set(postura, "postura_id", $$v)},expression:"postura.postura_id"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"8","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Cantidad","label-for":"single-quantity"}},[_c('b-form-spinbutton',{attrs:{"id":"single-quantity","min":"1","max":"100"},model:{value:(postura.number_eggs),callback:function ($$v) {_vm.$set(postura, "number_eggs", $$v)},expression:"postura.number_eggs"}})],1)],1),_c('b-col',{staticClass:"d-flex align-items-center justify-content-sm-end",attrs:{"cols":"12","md":"4","lg":"1"}},[_c('feather-icon',{staticClass:"cursor-pointer text-danger",attrs:{"disabled":index == 0,"icon":"TrashIcon","size":"20"},on:{"click":function($event){return _vm.deletePostura(index, postura)}}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')])],1)})],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }