<template>
	<div>
		<b-modal
			size="lg"
			body-class="m-0 p-0"
			@hide="$emit('close')"
			v-model="modalEggByMonth.show"
			hide-footers
			ok-only
			ok-variant="secondary"
			ok-title="Cerrar"
			title-class="py-25"
		>
			<!-- {{ yearSelect }}ss -->
			<template #modal-title>
				<strong>HUEVOS REGISTRADOS:</strong>
				<span>{{ withMonthFormated }}</span>
			</template>
			<div class="mt-2 w-full">
				<b-table :fields="fields" :items="items" foot-clone responsive sticky-header="calc(100vh - 200px)">
					<template #cell(quantity)="{ item }">
						<p class="mb-0 font-weight-bolder">
							{{ item.quantity }}
						</p>
					</template>

					<template #cell(madrillas)="{ item }">
						<p
							@click="selectIdEggsFunction(item)"
							class="mb-0 text-primary font-weight-bolder cursor-pointer"
						>
							{{ item.madrillas.length }}
						</p>
					</template>
					<template #cell(user)="{ item }">
						<div class="d-flex flex-column justify-content-center">
							<!-- <pre>{{ item }}</pre> -->
							<p class="mb-0 font-weight-bolder">{{ item.user.name }}</p>
							<p class="mb-0">{{ item.user.date | myGlobalDayShort }}</p>
						</div>
					</template>

					<template #foot(day)>
						<p class="mb-0"></p>
					</template>

					<template #foot(quantity)>
						<p class="mb-0">{{ sumNumbers(items, "quantity") }}</p>
					</template>

					<template #foot(user)>
						<p class="mb-0"></p>
					</template>
				</b-table>
			</div>
		</b-modal>
	</div>
</template>

<script>
import eggService from "@/services/egg.service"
import RegisterEggsByIdEgg from "./RegisterEggsByIdEgg.vue"
import moment from "moment"

export default {
	name: "EggsByMonth",
	props: {
		start_date: {
			type: [String],
			required: true,
		},
		title: {
			type: String,
			required: false,
		},
		selectMonth: {
			type: [String, Number],
			required: true,
		},
	},
	data() {
		return {
			yeatText: null,
			monthId: null,
		}
	},
	components: {
		RegisterEggsByIdEgg,
	},
	async mounted() {
		// console.log("ADD", this.selectMonth,);
		this.isPreloading()
		let getNumberMonth = parseInt(this.selectMonth.split("-")[1])
		let getYear = parseInt(this.selectMonth.split("-")[0])
		this.yeatText = getYear
		this.monthId = getNumberMonth
		const lastDay = new Date(this.yearSelect, getNumberMonth, 0).getDate()

		// const dateIn = `${getYear}-${getNumberMonth < 10 ? `0${getNumberMonth}` : getNumberMonth}-01`
		// const dateFn = `${getYear}-${getNumberMonth < 10 ? `0${getNumberMonth}` : getNumberMonth}-${
		// lastDay < 10 ? `0${lastDay}` : lastDay
		// }`
		// console.log("days", dateIn, dateFn)

		// return;
		const { data } = await eggService.getEggsByMonthDetailByUser({
			dateIn: this.start_date,
			dateFn: this.start_date,
		})

		this.items = data

		data.forEach((el) => {
			this.newMad = new Set([...this.newMad, ...el.madrillas])
		})
		console.log("sfd")
		if (this.selectMonth) {
			this.modalEggByMonth.show = true
		}
		this.isPreloading(false)
	},
	computed: {
		sumNumbers: () => (item, key) => {
			return item.reduce(function (previousValue, currentValue) {
				return previousValue + parseInt(currentValue[key])
			}, 0)
		},
		withMonthFormated() {
			moment.locale("es")
			return (
				" " +
				String(
					moment(`${this.yeatText}-${this.monthId < 10 ? `0${this.monthId}` : this.monthId}-01`).format(
						"MMMM"
					)
				)
					.substring(0, 3)
					.toUpperCase() +
				" " +
				this.yeatText
			)
		},
		modalTitleByDay() {
			return this.currentDayOnly + " " + this.withMonthFormated
		},
	},
	data() {
		const monthWithYear =
			String(moment(this.selectMonth).format("MMMM")).substring(0, 3).toUpperCase() + " " + this.yearSelect
		return {
			monthWithYear,
			modalEggByMonth: {
				title: null,
				show: false,
			},
			fields: [
				{ key: "day", label: "Dia", class: "text-center" },
				{ key: "quantity", label: "Cantidad", class: "text-center" },
				// { key: "madrillas", label: "Madrillas", class: "text-center" },
				{ key: "user", label: "Registrado por", class: "text-center" },
			],
			items: [],
			newMad: [],
			selectIdEggs: null,
			currentDateOnly: null,
			currentDayOnly: null,
		}
	},
	methods: {
		sortByColumn(key) {
			this.sortBy = key
			this.sortDesc = !this.sortDesc
		},
		openModalDetail(item) {
			const dateIn = `${this.yeatText}-${this.monthId < 10 ? `0${this.monthId}` : this.monthId}-${
				item.day < 10 ? "0" + item.day : item.day
			}`
			// this.currentDateOnly = item.date;
			this.currentDateOnly = dateIn
			this.currentDayOnly = item.day
			this.selectIdEggs = item
		},
		selectIdEggsFunction(item) {
			const dateIn = `${this.yeatText}-${this.monthId < 10 ? `0${this.monthId}` : this.monthId}-${
				item.day < 10 ? "0" + item.day : item.day
			}`
			// this.currentDateOnly = item.date;
			this.currentDateOnly = dateIn
			this.currentDayOnly = item.day
			this.selectIdEggs = item
		},
		refresh() {
			this.selectIdEggs = null
		},
	},
}
</script>

<style lang="scss" scoped>
.text-user-eggs {
	font-size: 12px !important;

	@media screen and (min-width: 900px) {
		font-size: 14px !important;
	}
}
</style>
